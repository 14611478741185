<template>
    <client-page-full-page class="app--main">

        <full-page ref="fullpage" :options="options" id="fullpage" class="main_fullpage">

            <div class="fullpage-section fullpage-section--visual">
                <div class="fullpage-inner">
                    <v-container>
                        <div class="visual-ani-group line-height-15 color-white">
                            <div class="visual-ani">
                                <h2 class="font-size-38 font-size-md-50 font-size-lg-80 font-weight-bold">
                                    MINART EZFLIP™ FRAME
                                </h2>
                            </div>
                            <div class="visual-ani">
                                <p class="page-text--lg color-grey-f5">
                                    Minart's unique snap frame technology. Exclusive built-in spring safety architecture.
                                </p>
                            </div>
                            <div class="visual-ani">
                                <p class="page-text--lg color-grey-f5">
                                    Introducing Minart's high-quality advertising solutions with more than 35 years of heritage.
                                </p>
                            </div>
                        </div>
                    </v-container>
                </div>
            </div>

            <div class="fullpage-section fullpage-section--series color-white">
                <v-row no-gutters class="h-lg-100">
                    <v-col cols="12" lg="6" class="px-12 fullpage-section--series__left">
                        <div class="fullpage-inner justify-lg-end text-lg-right">
                            <div class="pr-lg-50 pr-xl-98" data-aos="fade-left">
                                <p class="page-text letter-spacing-6 color-primary line-height-1 mb-20 mb-lg-60">
                                    PRODUCT FOCUS
                                </p>
                                <p class="page-text--lg line-height-15 mb-4 mb-lg-8">
                                    Easy-to-Install Window-mounted illuminated Snap Frame
                                </p>
                                <h3 class="tit font-weight-bold line-height-1 mb-60 mb-lg-140">
                                    MINPOP™ 70
                                </h3>
                                <btn-primary to="/en/series/product/63bfa69c0bb7f010b1860113" tit="Discover"></btn-primary>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="px-12 fullpage-section--series__right">
                        <div class="fullpage-inner">
                            <div class="pl-lg-50 pl-xl-98" data-aos="fade-right">
                                <p class="page-text letter-spacing-6 color-primary line-height-1 mb-20 mb-lg-60">
                                    PRODUCT FOCUS
                                </p>
                                <p class="page-text--lg line-height-15 mb-4 mb-lg-8">
                                    Outdoor Ready! Extra Durable Illuminated Bezel-less Fabric Panel
                                </p>
                                <h3 class="tit font-weight-bold line-height-1 mb-60 mb-lg-140">
                                    QFABRIC™
                                </h3>
                                <btn-primary to="/en/series/product/63bfb07b0bb7f010b186059e" tit="Discover"></btn-primary>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="fullpage-section fullpage-section--partners">
                <div class="fullpage-inner">
                    <v-container>
                        <div class="tit-wrap pb-40 pb-sm-0 text-center">
                            <h3 class="tit line-height-1 font-weight-bold" data-aos="fade-up">
                                PROJECTS
                            </h3>
                            <router-link to="/en/business" class="text-btn">READ MORE +</router-link>
                        </div>
                        <div class="partners-slide">
                            <div class="swiper-container">
                                <div class="swiper-wrapper">

                                    <!-- S: 파트너사 -->
                                    <div class="swiper-slide" v-for="logo, idx in logos" :key="idx">
                                        <div class="partner">
                                            <v-img :src="logo.image" contain class="w-100"></v-img>
                                        </div>
                                    </div>
                                    <!-- E: 파트너사 -->

                                </div>
                            </div>
                            <v-btn icon outlined color="grey-7" class="slide-btn slide-prev"><i class="icon icon-left-arrow-grey"></i></v-btn>
                            <v-btn icon outlined color="grey-7" class="slide-btn slide-next"><i class="icon icon-right-arrow-grey"></i></v-btn>
                        </div>
                    </v-container>
                </div>
            </div>

            <div class="fullpage-section fullpage-section--inquiry color-white">
                <div class="fullpage-inner">
                    <v-container>
                        <div class="d-flex flex-column align-center text-center">
                            <div data-aos="fade-up">
                                <v-img src="/images/ci-color.svg" contain class="w-100px w-lg-180px mb-20 mb-lg-60"></v-img>
                            </div>
                            <p class="page-text--lg line-height-15 mb-60 mb-lg-120">
                                Minart offers a variety of ready-made products as well as made-to-order products.<br>
                                Feel free to contact us today.
                            </p>
                            <btn-primary to="/en/inquiry" tit="Inquiry"></btn-primary>
                        </div>
                    </v-container>
                </div>
            </div>    

            <div class="fullpage-section fullpage-section--footer fp-auto-height">
                <main-footer></main-footer>
            </div>  

        </full-page>


        <popup-layer></popup-layer>

    </client-page-full-page>
</template>

<script>
// import Swiper from "swiper/bundle";
import ClientPageFullPage from "@/pages/client/en/templates/ClientPageFullPage.vue";
import PopupLayer from '@/components/client/en/popup/popup-layer.vue';
import MainFooter from "@/components/client/en/inc/main-footer.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

import api from '@/api';

export default {
    components: {
        ClientPageFullPage,
        PopupLayer,
        MainFooter,
        BtnPrimary,
    },
    props: {
    },
    data() {
        return {
            // fullpage
            options: {
                navigation: true,
                animateAnchor: false,
                anchors: ['Visual', 'Series', 'Partners', 'Inquiry'],
                sectionSelector: '.fullpage-section',
                fitToSection: true,
                scrollingSpeed: 600,
                responsiveWidth: 1200,
                responsiveHeight: 800,
                // scrollOverflow: true,
                easingcss3: 'ease-in-out',
                licenseKey: 'BE202675-E3234C07-967F61A1-57A110B1',
                onLeave: this.onLeave,
                onSlideLeave: this.onSlideLeave,
                afterLoad: this.afterLoad,
                afterSlideLoad:this.afterSlideLoad,
                scrollBar:true,
            },

            logos:[]
        }
    },
    mounted() {
        this.init();
        this.getLogo();
    },    
    methods: {
        async init() {
            this.$nextTick(() => {

                setTimeout(function(){
                    this.Partners = new Swiper('.partners-slide .swiper-container', {
                        spaceBetween : 0,
                        slidesPerView: 3,
                        grid: {
                            rows: 4,
                        },
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: false,
                        navigation: {
                            prevEl: '.partners-slide .slide-prev',
                            nextEl: '.partners-slide .slide-next'
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 4,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        },
                    });
                }, 10);
            });

                let imgs = await api.v1.boards.gets({ params: { code: "mainBoard"}});
                this.mainImgs = imgs.boards;
                let video = await api.v1.boards.gets({ params: { code: "mainViedo"}});
                this.mainVideo = video.boards[0].meta;

                this.videoId = urlParser.parse(this.mainVideo?.youtube)?.id || "";
            },
            async getLogo() {
                let { banners } = await api.v1.banners.gets();
                this.logos = banners.map(el => el.slides[0]);
            },
            // fullpage
            onLeave: function(){
                var fpActive = document.querySelector('.fp-section.active');
                var fpAos = fpActive.querySelectorAll('.aos-init');
                fpAos.forEach((item) => {
                    item.classList.remove('aos-animate');
                });
            },
            onSlideLeave: function(){
                var fpActive = document.querySelector('.fp-section.active');
                var fpAos = fpActive.querySelectorAll('.aos-init');
                fpAos.forEach((item) => {
                    item.classList.remove('aos-animate');
                });
            },
            afterLoad: function(origin, destination, direction){
                var fpActive = document.querySelector('.fp-section.active');
                var fpAos = fpActive.querySelectorAll('.aos-init');
                fpAos.forEach((item) => {
                    item.classList.add('aos-animate');
                });
            },
            afterSlideLoad: function(){
                var fpActive = document.querySelector('.fp-section.active');
                var fpAos = fpActive.querySelectorAll('.aos-init');
                fpAos.forEach((item) => {
                    item.classList.add('aos-animate');
                });
            },

        },
};
</script>

<style>
html{
    overflow: scroll !important;
}
</style>

<style scoped>
@media (min-width:1200px){
    .fade-left{
        opacity: 0;
        transform: translateX(80px);
    }
    .fade-right{
        opacity: 0;
        transform: translateX(-80px);
    }
    .fade-up{
        opacity: 0;
        transform: translateX(80px);
    }
    .fade-down{
        opacity: 0;
        transform: translateX(-80px);
    }

    .fp-table.active .fade-left{
        animation: fadeLeft 0.5s 0.7s ease-out forwards;
    }
    .fp-table.active .fade-right{
        animation: fadeRight 0.5s 0.7s ease-out forwards;
    }
    .fp-table.active .fade-up{
        animation: fadeUp 0.5s 0.7s ease-out forwards;
    }
    .fp-table.active .fade-down{
        animation: fadedown 0.5s 0.7s ease-out forwards;
    }

    @keyframes fadeLeft{
        0%{
            opacity: 0;
            transform: translateX(80px);
        }
        100%{
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes fadeRight{
        0%{
            opacity: 0;
            transform: translateX(-80px);
        }
        100%{
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes fadeUp{
        0%{
            opacity: 0;
            transform: translateY(80px);
        }
        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes fadeDown{
        0%{
            opacity: 0;
            transform: translateY(-80px);
        }
        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.partners-slide{
    margin: -1px;
    animation: move 0.01s 0.5s forwards;
}

@keyframes move{
    0%{
        margin: -1px;
    }
    100%{
        margin: 0;
    }
}

</style>